const loadJs = async (el, {expression}, {evaluate}) => {
    const paths = evaluate(expression)
    await paths.reduce(
        async (p, url) => {
            await p
            return loadScript(url)
        },
        Promise.resolve()
    )

    const callback = el.attributes["x-load-js-callback"]?.value;
    if (callback) {
        evaluate(callback)
    }
}

const loadScript = function (url) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = url

        script.addEventListener('load', () => {
            resolve(true)
        });

        document.head.appendChild(script)
    });
};

export default loadJs
