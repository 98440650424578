const map = {
    endpoint: '/presentation/api/component/commonMap', isOpen: false, error: false, loading: false, html: false,

    init() {
        if (sessionStorage?.getItem("mapIsActive") === '1' || window.location.search.indexOf("mapstate=") !== -1) {
            this.toggleMap()
        }
    },

    async toggleMap() {
        if (window.self !== window.top) {
            return
        }

        if (!bergfex?.MapHandler?.showMap || !bergfex?.MapHandler?.hideMap) {
            sessionStorage.setItem('mapIsActive', '1')
            await this._loadMapData()
            return
        }

        if (this.isOpen) {
            bergfex.MapHandler.hideMap()
            this.isOpen = false
            sessionStorage.setItem('mapIsActive', '0')
            return
        }

        bergfex.MapHandler.showMap(() => {
            this.isOpen = true
            sessionStorage.setItem('mapIsActive', '1')
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    },

    async _loadMapData() {
        if (this.html || this.loading) {
            return
        }

        this.loading = true

        let searchParams = new URLSearchParams(window.location.search)
        let endpoint = this.endpoint
        if (searchParams.has('lat') && searchParams.has('lng')) {
            endpoint += '?' + searchParams.toString()
            endpoint += '&caching=0'
        }

        try {
            const response = await fetch(endpoint)
            const jsonData = await response.json()
            if (!jsonData.result || jsonData.error) {
                this.error = true
                this.loading = false
                return
            }

            this.html = jsonData.result

            this.loading = false
        } catch (error) {
            this.error = true
            this.loading = false
        }
    },

    initializeMap(lat = false, lng = false, name = null) {
        if (!bergfex?.MapHandler?.init || !leafletMapOptions) {
            return
        }

        bergfex.MapHandler.init(leafletMapOptions)
        bergfex.MapHandler.showMap(() => {
            this.isOpen = true

            if (lat && lng) {
                const marker = L.marker(L.latLng(lat, lng))
                marker.addTo(bergfex.MapHandler.map.getMap())

                if (name) {
                    marker.bindPopup(name).openPopup()
                }

                setTimeout(() => {
                    bergfex.MapHandler.setTarget(lat, lng, 14, 'o')
                }, 500)
            }

            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    },

    get mapContent() {
        if (this.error || !this.html) {
            return ''
        }

        return this.html
    },
}

export default map
