const fillupAds = () => ({
  bounding: null,
  ads: [],
  gap: 16,

  async init() {
    this.bounding = this.$el.getBoundingClientRect()

    if (this.bounding.height <= 0 || this.bounding.width <= 0) {
      return
    }

    if (!this.$el.getClientRects().length) {
      return
    }

    if (this.bounding.height < bergfex.ads.content.height || this.bounding.width < bergfex.ads.content.width) {
      return
    }

    await this.$nextTick()
    this.ads = new Array(Math.floor(this.bounding.height / (bergfex.ads.content.height + this.gap)))
  },

  load() {
    bergfex.contentAds.initContentAd(this.$el.querySelector('.partner'))
  },
})

export default fillupAds
