const dynamicBackgroundSwap = (timeout) => ({
    dataAttribute: 'data-background2x',
    identifier: '',
    timeout: false,
    init() {
        this.identifier = this.$el
        this.timeout = timeout ?? 0
    },

    intersect() {
        if (window.devicePixelRatio <= 1.5) {
            return
        }

        setTimeout(() => {
            const fileBackground2x = this.identifier.getAttribute(this.dataAttribute)
            let img = new Image()
            img.src = fileBackground2x
            img.onload = () => {
                this.identifier.style.backgroundImage = `url(${fileBackground2x})`
            }
        }, this.timeout * 1000)
    }
})

export default dynamicBackgroundSwap
