import tagmanager from './tagmanager'

const privacyManagerId = 402986
const cmpLoaded = typeof SDG !== "undefined"

export const init = () => {

    // add trigger to open privacy manager
    document.querySelectorAll('.js-trigger-privacy-manager').forEach((el, i) => {
        el.addEventListener('click', e => {
            e.preventDefault()
            openPrivacyManager()
        });
    })

    // add listener for Google Analytics
    onCustomVendorConsent('Google Analytics', () => {
        tagmanager.gtag('js', new Date())

        tagmanager.consent('update', {
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
        })

        tagmanager.event('AnalyticsOptIn')
    })

    // add listener for Google Ads
    onIABVendorConsent(755, () => {
        tagmanager.consent('update', {
            'ad_storage': 'granted',
            'ad_personalization': 'granted',
            'ad_user_data': 'granted',
        })
        tagmanager.event('GoogleAdsOptIn')
    })

}

const onCustomVendorConsent = (vendor, success, fallback = null) => {
    if (!cmpLoaded) {
        return
    }

    fallback = fallback || function () {}

    window.addEventListener('metaTagSystemCmpConsentAvailable', eventObject => {
        return (SDG.Publisher.getCustomVendorConsent(vendor) === true) ? success() : fallback()
    })
}


const onIABVendorConsent = (id, callback) => {
    if (!cmpLoaded) {
        return
    }
    window.addEventListener('metaTagSystemCmpConsentAvailable', eventObject => {
        SDG.Publisher.getIABVendorConsent(id, returnValue => {
            if (returnValue) {
                callback()
            }
        });
    })

}

const openPrivacyManager = () => {
    if (!cmpLoaded) {
        return
    }

    window._sp_.gdpr.loadPrivacyManagerModal(privacyManagerId)
}


export default {onCustomVendorConsent, openPrivacyManager}
