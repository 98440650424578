
bergfex = bergfex || {}
bergfex.promotionZones = {
    skyscraper: 2,
    promotion: 3000,
    content: 46,
}
bergfex.ads = {
    content: {
        height: 270,
        width: 320,
    }
}
