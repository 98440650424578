import {defaultGridItems, getMaxItemsPerGridPage} from "../../abstracts/responsive"

const list = (data) => ({
    isLoading: false,
    items: {},
    itemsDisplayed: 8,
    defaultGridItems: defaultGridItems,
    endpoint: '/presentation/api/pagecount/promotions/',

    init() {
        let itemsPerPage = getMaxItemsPerGridPage()
        this.initGridItems(itemsPerPage)
        this.itemsDisplayed = this.defaultGridItems[itemsPerPage]
        this.items = data.items || {}

        this.sendPromotionBoxesPageCount(Object.values(this.items).slice(0, this.itemsDisplayed))
    },

    initGridItems(itemsPerPage) {
        //step exists not set in show current
        if (!this.defaultGridItems[itemsPerPage]) {
            this.defaultGridItems[itemsPerPage] = itemsPerPage
        }

        if (data && data.preloaded && data.preloaded > 0) {
            //override default
            for (let items in this.defaultGridItems) {
                this.defaultGridItems[items] = data.preloaded
            }
        }
    },

    loadMoreItems() {
        this.sendPromotionBoxesPageCount(Object.values(this.items).slice(this.itemsDisplayed), true);
    },

    async sendPromotionBoxesPageCount(promotionIds, updateItems = false) {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true

        await fetch(this.endpoint.toString(), {
            method: 'POST',
            headers: {
                "x-requested-with": "XMLHttpRequest",
                "content-type": "application/json",
            },
            redirect: "follow",
            body: JSON.stringify({
                'promotionIds': promotionIds.filter(item => item.type === 'promotion').map(item => item.id),
            })
        })
        this.isLoading = false
        if (updateItems) {
            this.itemsDisplayed = this.items.length
        }

    },

    areMoreItemsToLoad() {
        return this.itemsDisplayed < this.items.length
    }
})

export default list